import { Controller } from "stimulus";
import { useDispatch, useThrottle, useDebounce } from 'stimulus-use';
import { loadModalUrl } from "../modal/utils";

export default class extends Controller {
  static targets = ["header", "navigationMenuMobile", "mainMenuItem", "cartItemsCount"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect () {
    useThrottle(this, { wait: 50 });
    useDebounce(this, { wait: 50 });
    useDispatch(this);
    const isSubHeaderPresent = this.data.get("sub-header") === 'true'

    if (isSubHeaderPresent) {
      this.headerTarget.classList.add('has-sub-header');
    }

    this.handleHeaderPosition();

    document.addEventListener("click", this.closeSubMenuIfClickOutside);
  }

  handleScroll = () => {
    this.handleHeaderPosition();
  };

  handleScrollEnd = () => {
    this.handleHeaderPosition();
  };

  handleHeaderPosition() {
    const { scrollY, location: { pathname } } = window;
    const paths = ['/account', '/groups-and-events', '/sukkot-orlando'];

    if (scrollY > 64 || paths.includes(pathname)) {
      this.headerTarget.classList.add('scrolled');
      this.closeSubMenus();
    } else {
      this.headerTarget.classList.remove('scrolled');
    }
  }

  closeSubmenu(e) {
    const mainMenuItem = this.mainMenuItemTargets.find(
      (mainMenuItemTarget) => mainMenuItemTarget.contains(e.currentTarget)
    );
    mainMenuItem.classList.remove("open");
  }

  openSubmenu(e) {
    e.currentTarget.classList.add("open");
  }

  closeSubMenus() {
    this.mainMenuItemTargets.forEach(mainMenu => mainMenu.classList.remove("open"));
  }

  toggleMobileMenu() {
    this.navigationMenuMobileTarget.classList.toggle("open");
    document.body.classList.toggle("overflow-hidden-below-desktop");
  }

  async openAccountModal(event) {
    event.preventDefault();
    const linkTag = event.target.closest("a");
    if (!linkTag) return;
    const link = linkTag.getAttribute("href");
    loadModalUrl(link);
  }

  handleSearchButtonClick() {
    this.dispatch('openSearchSidebar');
  }

  fixHeader() {
    if (!this.headerTarget.classList.contains('unfixed')) return;

    this.headerTarget.classList.remove('unfixed');
  }

  unfixHeader() {
    if (this.headerTarget.classList.contains('unfixed')) return;

    this.headerTarget.classList.add('unfixed');
  }

  closeSubMenuIfClickOutside(event) {
    const mainMenu = document.querySelector('.main-menu'); 
    
    if (!mainMenu || !mainMenu.classList.contains('open')) return;

    const menuItem = mainMenu.querySelector('.main-menu-item[title="DESTINATIONS"]');
    if (menuItem) {
      const clickedElement = event.target;
  
      if (clickedElement.tagName !== 'A') {
        mainMenu.classList.remove('open');
      }
    }
  }
}
