import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["subMenu"];

  connect() {
    this.checkInitialActiveSubMenuPresence();
  }

  checkInitialActiveSubMenuPresence() {
    const isActiveSubMenuPresent = this.subMenuTargets.find(subMenu => subMenu.classList.contains('active'));

    if (!isActiveSubMenuPresent) {
      const mainSubMenu = this.subMenuTargets.find(subMenu => subMenu.getAttribute('id') === '0');
      mainSubMenu.classList.add('active');
    }
  }

  handleClickItem(e) {
    e.preventDefault();
    
    const item = e.currentTarget;
    const targetMenuId = item.getAttribute("item-id");
    const newActiveMenu = this.subMenuTargets.find((menu) => menu.id === targetMenuId);
    const itemLinkEl = item.querySelector('a');

    if (newActiveMenu) {
      this.switchActiveSubMenu(newActiveMenu);
      return;
    }

    if (itemLinkEl) {
      this.followItemLink(itemLinkEl.getAttribute('href'));
    }
  }

  followItemLink(link) {
    window.location = link;
  }

  switchActiveSubMenu(menu) {
    this.subMenuTargets.forEach((subMenu) => subMenu.classList.remove('active'));
    menu.classList.add('active');
  }

  switchToInitialMenu() {
    const initalMenu = this.subMenuTargets.find((menu) => menu.id === '0');

    this.subMenuTargets.forEach((menu) => menu.classList.remove('active'));
    initalMenu.classList.add('active');
  }

  switchToSecondLevelMenu() {
    const initalMenu = this.subMenuTargets.find((menu) => menu.getAttribute('data-levelId') === '1');
    this.subMenuTargets.forEach((menu) => menu.classList.remove('active'));
    initalMenu.classList.add('active');
  }
}
