import {Controller} from "stimulus";
import {useDispatch} from "stimulus-use";
import {setCookie} from "init/cookie";
import getFilters from "util/filter_params";

export default class extends Controller {
    static targets = [
        "dropdown",
        "dropdownBtn",
        "datesDropdownBtn",
        "guestsDropdownBtn",
        "searchbarContainer",
        "destination",
        "destinationTitle",
        "adultsCount",
        "childrenCount",
        "guestsCount",
        "arriveInput",
        "departInput",
        "datesLabel",
        "searchbarContainerMini",
        "destinationTitleMini",
        "datesLabelMini",
        "guestsCountMini",
        "guestsWrapper",
        "destinationDropdown",
        "submitBtn",
        "dropdownGuests",
        "dropdownDates",
        "counterBtn"
    ];

    searchParams = new URLSearchParams(window.location.search);

    connect() {
        useDispatch(this);
        this.resortDestinationType = "resort";
        this.cityDestinationType = "city"
        this.defaultDestination = "";
        this.absoluteSearchbarPosition = this.getAbsoluteSearchbarPosition();
        this.renderedAdultsCount = getFilters().get("adults") || "0";
        this.renderedChildrenCount = getFilters().get("children") || "0";

        console.log(Boolean(this.searchbarContainerTarget.dataset.page))

        this.setInitialDestination();
        this.updateDates(true);
        this.updateGuestsCount(true);
        this.saveSearchDataToCookies();

        window.document.addEventListener(
            "filters:update",
            this.updateSearchParams.bind(this)
        );

        window.document.addEventListener(
            "filters:submit",
            this.handlerFilters.bind(this)
        );

        window.document.addEventListener(
            "filters:reset",
            this.resetFilters.bind(this)
        );

        window.document.addEventListener(
            "filters:clearDates",
            this.resetDates.bind(this)
        );

        const shouldScroll = localStorage.getItem("shouldScroll");
        if (shouldScroll === "true") {
          const scrollPosition = localStorage.getItem("scrollPosition");
            if (scrollPosition) {
              window.scrollTo({
                top: scrollPosition,
                behavior: "smooth"
              });
              setTimeout(() => {
                this.switchToNormalMode();
                if (
                  this.renderedAdultsCount === "0" &&
                  this.renderedChildrenCount === "0"
                ) {
                  const dropdown = this.dropdown(this.guestsDropdownBtnTarget);
                  const icon = this.guestsDropdownBtnTarget.querySelector(
                    ".collapse-icon"
                  );
                  icon.classList.add("active");
                  dropdown.classList.add("open");
                  dropdown.classList.toggle("below");
                }
              }, 1500);
              localStorage.removeItem("scrollPosition");
              localStorage.removeItem("shouldScroll");
          }
        }
    }

    openCalendar() {
        this.switchToNormalMode()
        const dropdown = this.dropdown(this.datesDropdownBtnTarget);
        const icon = this.datesDropdownBtnTarget.querySelector('.collapse-icon');

        this.dropdownTargets.forEach(dropdownTarget => {
            if (dropdownTarget === dropdown) return;
            this.closeDropdown(dropdownTarget);
        });

        icon.classList.toggle('active')
        dropdown.classList.toggle("open");
        dropdown.classList.remove("below");
        dropdown.classList.remove("above");
        this.handleDropdownPosition(dropdown);
    }

    getAbsoluteSearchbarPosition() {
        let absoluteY = 0;
        let currentElement = this.searchbarContainerTarget;

        // Traverse the offset parent chain until body element is reached
        while (currentElement.offsetParent !== null) {
            // Add current element's offsetTop to the absoluteY value
            absoluteY += currentElement.offsetTop;
            // Move to the next offset parent
            currentElement = currentElement.offsetParent;
        }

        return absoluteY;
    }

    setActiveDestination(destination) {
        destination.classList.add('active');
        this.destinationPath = destination.querySelector("a").getAttribute("href");
        this.destinationType = destination.getAttribute("item-type");
        if (this.destinationType === this.cityDestinationType) {
            this.destinationDropdownTarget.classList.add('block-dropdown');
        }
        this.destinationTitleTarget.textContent = destination.textContent;
        this.destinationTitleMiniTarget.textContent = destination.textContent;
    }

    setInitialDestination() {
        this.destinationPath = this.defaultDestination;
        const locationPath = window.location
            .toString()
            .replace(window.location.search, "");

        const matchedDestinations = this.destinationTargets.filter(destination => {
            const destinationLinkEl = destination.querySelector("a");
            if (!destinationLinkEl) return false;

            const destinationLink = destinationLinkEl.getAttribute("href");
            return locationPath.includes(destinationLink);
        });


        if (!matchedDestinations.length) {
            const cityDestination = this.destinationTargets.find(destination =>
                destination.getAttribute("item-type") === this.cityDestinationType
            );

            // for splp
            if (this.searchbarContainerTarget.dataset.resortlink) {
                this.destinationPath = this.searchbarContainerTarget.dataset.resortlink;
                this.destinationType = this.resortDestinationType;
                this.destinationTitleTarget.textContent = this.searchbarContainerTarget.dataset.resortname;
                this.destinationTitleMiniTarget.textContent = this.searchbarContainerTarget.dataset.resortname;
                const matchedDestinationsSPLP = this.destinationTargets.filter(destination => {
                    const destinationLinkEl = destination.querySelector("a");
                    if (!destinationLinkEl) return false;
        
                    const destinationLink = destinationLinkEl.getAttribute("href");
                    return destinationLink.includes(this.searchbarContainerTarget.dataset.resortlink);
                });
                const destination = matchedDestinationsSPLP[0];
                this.setActiveDestination(destination);
                return
            }

            if (cityDestination) {
                const cityLinkEl = cityDestination.querySelector("a");
                if (cityLinkEl) {
                    this.destinationPath = cityLinkEl.getAttribute("href");
                    this.destinationType = cityDestination.getAttribute("item-type");
                    this.destinationTitleTarget.textContent = cityDestination.textContent;
                    this.destinationTitleMiniTarget.textContent = cityDestination.textContent;
                    this.destinationDropdownTarget.classList.add('block-dropdown');
                    return;
                }
            } 
        }

        if (matchedDestinations.length > 1) {
            // keep it if we return to url as .com/city/resort
            const destination = matchedDestinations.reduce((el1, el2) => {
                const [link1, link2] = [el1, el2].map(el =>
                    el.querySelector("a").getAttribute("href")
                );

                const activeEl = link1.length > link2.length ? el1 : el2;
                return activeEl;
            });

            this.setActiveDestination(destination);
        } else {
            // for links .com/resort
            const destination = matchedDestinations[0];
            this.setActiveDestination(destination);
        }

    }

    handleMouseDown(e) {
        const isDropdown = this.dropdownTargets.find(dropdownTarget =>
            dropdownTarget.contains(e.target)
        );
        const isDropdownBtn = this.dropdownBtnTargets.find(dropdownBtnTarget =>
            dropdownBtnTarget.contains(e.target)
        );
        if (!isDropdown && !isDropdownBtn) {
            this.closeDropdowns();
        }
    }

    clickSearchbarFilter(e) {
        // On mobile, we need first click sticky top bar
        if (this.searchbarContainerMiniTarget.classList.contains('visible')) {
            this.searchbarContainerMiniTarget.click();
        }

        switch (e.detail.type) {
            case 'dates':
                this.datesDropdownBtnTarget.click();
                break;
            case 'guests':
                this.guestsDropdownBtnTarget.click();
                this.guestsWrapperTarget.classList.add('restricted-guests');
                break;
            default:
                return;
        }
    }

    toggleDropdown(e) {
        const dropdown = this.dropdown(e.currentTarget);
        const icon = e.currentTarget.querySelector('.collapse-icon')

        this.dropdownTargets.forEach(dropdownTarget => {
            if (dropdownTarget === dropdown) return;
            this.closeDropdown(dropdownTarget);
        });

        icon.classList.toggle('active')
        dropdown.classList.toggle("open");
        dropdown.classList.remove("below");
        dropdown.classList.remove("above");
        this.handleDropdownPosition(dropdown);
        if (dropdown.classList.contains('open')) {
            if (this.searchbarContainerTarget) {
                const dropdownRect = dropdown.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const isDropdownVisible = (
                    dropdownRect.top >= 0 &&
                    dropdownRect.bottom <= viewportHeight
                );
    
                if (!isDropdownVisible) {
                    const topPosition = this.searchbarContainerTarget.getBoundingClientRect().top + window.pageYOffset - 90;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                }
            }
        }
    }

    handleDropdownPosition(dropdown) {
        const headersHeight = 150;
        // if the remaining space from searchbar to the top of the screen is low
        // a dropdown will be opened under the searchbar
        if (
            this.absoluteSearchbarPosition <
            dropdown.offsetHeight + headersHeight
        ) {
            dropdown.classList.toggle("below");
            return;
        }

        // open a dropdown above or under the seachbar depending on scroll position
        const searchbarRelativePosition =
            this.searchbarContainerTarget.getBoundingClientRect().top + 30;

        if (searchbarRelativePosition > Math.floor(window.innerHeight / 2)) {
            dropdown.classList.toggle("above");
        } else {
            dropdown.classList.toggle("below");
        }
    }

    saveScrollPosition() {
      const productCardList = document.querySelector('.product-card-list');
      const resortCardList = document.querySelector(".resort-card-list-map");
      const existingBlock = productCardList || resortCardList;
      if (existingBlock) {
        const scrollPosition = existingBlock.getBoundingClientRect().top + window.scrollY - 100;
        localStorage.setItem("scrollPosition", scrollPosition);
        localStorage.setItem("shouldScroll", "true");
      }
    }

    closeDropdown(dropdown) {
      if (
        dropdown === this.dropdownGuestsTarget &&
        this.dropdownGuestsTarget.classList.contains("open") &&
        (this.adultsCountTarget.value !== this.renderedAdultsCount ||
          this.childrenCountTarget.value !== this.renderedChildrenCount)
      ) {
        if (this.searchbarContainerTarget.dataset.page) {
          this.submitPickUnit();
        } else {
          this.saveScrollPosition();
          this.submit();
        }
      } 
      dropdown.classList.remove("open");
      dropdown.classList.remove("above");
      dropdown.classList.remove("below");
    }

    dropdown(sibling) {
        const siblings = [...sibling.parentNode.children].filter(
            c => c !== sibling
        );
        const dropdown = this.dropdownTargets.find(dropdownTarget =>
            siblings.includes(dropdownTarget)
        );
        return dropdown;
    }

    closeDropdowns() {
        this.dropdownTargets.forEach(dropdownTarget => {
            this.closeDropdown(dropdownTarget);
        });
    }

    selectDestination(e) {
        e.preventDefault();

        const destination = e.currentTarget;
        const destinationLink = destination.querySelector("a");

        if (!destinationLink) return;

        this.destinationTitleTarget.textContent = destination.textContent;
        this.destinationTitleMiniTarget.textContent = destination.textContent;
        this.destinationPath = destinationLink;
        this.destinationType = destination.getAttribute("item-type");
        this.submitBtnTarget.removeAttribute("disabled", "");
        this.destinationDropdownTarget.classList.remove('error');
        this.closeDropdowns();
    }

    updateGuestsCount(update_url = false) {
        
        if (!(this.adultsCountTarget.value !== this.renderedAdultsCount) ||
            !(this.childrenCountTarget.value !== this.renderedChildrenCount)
          ) {
            this.counterBtnTarget.classList.add("show");
          }
        const filters = getFilters({
            adults: this.adultsCountTarget.value,
            children: this.childrenCountTarget.value
        }, update_url === true);

        console.log(filters.get('adults'), filters.get('children'))

        const adultsCount = parseInt(filters.get('adults') || '0', 10);
        const childrenCount = parseInt(filters.get('children') || '0', 10);
        const adultsWording = adultsCount === 1 ? "Adult" : "Adults";
        const childrenWording = childrenCount === 1 ? "Child" : "Children";
        const guestsWording =
            adultsCount + childrenCount === 1 ? "guest" : "guests";
        this.guestsCountTarget.textContent = `${adultsCount} ${adultsWording}, ${childrenCount} ${childrenWording}`;
        this.guestsCountMiniTarget.textContent = `${adultsCount +
        childrenCount} ${guestsWording}`;

        if (adultsCount) {
            this.guestsWrapperTarget.classList.remove('restricted-guests');
        }
    }

    updateDates(update_url = false) {
        const filters = getFilters({
            checkin: this.arriveInputTarget.value,
            checkout: this.departInputTarget.value
        }, update_url === true);

        if (!filters.has('checkin') || !filters.has('checkout')) {
            return;
        }

        const arriveDate = new Date(filters.get('checkin').replace(/-/g, "/"));
        const departDate = new Date(filters.get('checkout').replace(/-/g, "/"));
        const arriveDateFull = arriveDate
            .toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            })
            .replace(",", "")
            .replace(/(\d{1,2}) (\d{4})$/, "$1, $2");
        const departDateFull = departDate
            .toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            })
            .replace(",", "")
            .replace(/(\d{1,2}) (\d{4})$/, "$1, $2");
        const arriveDateShort = arriveDate.toLocaleDateString("en-us", {
            month: "2-digit",
            day: "2-digit"
        });
        const departDateShort = departDate.toLocaleDateString("en-us", {
            month: "2-digit",
            day: "2-digit"
        });
        this.datesLabelTarget.textContent = `${arriveDateFull} - ${departDateFull}`;
        this.datesLabelMiniTarget.textContent = `${arriveDateShort} - ${departDateShort}`;
        this.dispatch("date-added")
    }

    resetDates() {
        this.datesLabelTarget.textContent = `Select Dates`;
        this.datesLabelMiniTarget.textContent = `Select Dates`;
        this.searchParams.delete('checkin');
        this.searchParams.delete('checkout');
        if (this.searchbarContainerTarget.dataset.page) {
          this.submitPickUnit();
        } else {
          this.submit();
        }
    }

    switchToMiniMode() {
        this.searchbarContainerTarget.classList.add("mini");
        this.searchbarContainerMiniTarget.classList.add("visible");
        this.dispatch("switchedToMiniMode");
    }

    switchToNormalMode() {
        this.searchbarContainerTarget.classList.remove("mini");
        this.searchbarContainerMiniTarget.classList.remove("visible");
        this.dispatch("switchedToNormalMode");
    }

    updateSearchParams(evt) {
        this._resetFilters();
        evt.detail.formData.forEach((value, name) => {
            this.searchParams.set(name, value);
        });
    }

    handlerFilters(evt) {
        this.updateSearchParams(evt)
        if (this.searchbarContainerTarget.dataset.page) {
          this.submitPickUnit();
        } else {
          this.submit();
        }
    }

    _resetFilters() {
        const keep = [
            "display",
            "resort",
            "sort_by",
            "checkin",
            "checkout"
        ];
        const markedToRemove = [];
        this.searchParams.forEach((_, key) => {
            if (!keep.includes(key)) {
                markedToRemove.push(key);
            }
        });

        markedToRemove.forEach(k => this.searchParams.delete(k));
    }

    resetFilters() {
        this._resetFilters();
        window.location = `${
            window.location.pathname
        }?${this.searchParams.toString()}`;
    }

    saveDatesToCookies() {
        const arrive = this.arriveInputTarget.value;
        const depart = this.departInputTarget.value;

        if (!arrive || !depart) return;

        const dates = {
            checkin: arrive,
            checkout: depart
        };
        setCookie("filter_by_dates", JSON.stringify(dates), 3);
    }

    saveGuestsToCookies() {
        const adultsCount = this.adultsCountTarget.value;
        const childrenCount = this.childrenCountTarget.value;

        if (!adultsCount && !childrenCount) return;

        const guests = {
            adults: adultsCount,
            children: childrenCount
        };
        setCookie("filter_by_guests", JSON.stringify(guests), 3);
    }

    saveSearchDataToCookies() {
        this.saveDatesToCookies();
        this.saveGuestsToCookies();
    }

    submit(e) {
        if (e) { e.preventDefault(); }

        this.saveSearchDataToCookies();
        this.searchParams.delete("page");
        if (this.adultsCountTarget.value !== "" &&
            this.adultsCountTarget.value !== "0") {
            this.searchParams.set("adults", this.adultsCountTarget.value);
        } else {
            this.searchParams.delete("adults");
        }

        if (this.childrenCountTarget.value !== "" &&
            this.childrenCountTarget.value !== "0") {
            this.searchParams.set("children", this.childrenCountTarget.value);
        } else {
            this.searchParams.delete("children");
        }

        if (this.arriveInputTarget.value !== "")
            this.searchParams.set("checkin", this.arriveInputTarget.value);

        if (this.departInputTarget.value !== "")
            this.searchParams.set("checkout", this.departInputTarget.value);

        let query = this.searchParams.toString();
        if (query !== "") {
            query = `?${query}`
        }

        if (this.destinationType === this.resortDestinationType) {
            window.location = query
                ? `${this.destinationPath}/accommodations${query}`
                : `${this.destinationPath}/accommodations`;
        } else if (this.destinationType === this.cityDestinationType) {
            window.location = query
                ? `${this.destinationPath}${query}`
                : this.destinationPath;
        } else if (this.destinationType === null) {
            // for All destination
            window.location = query
                ? `${this.destinationPath}/${query}`
                : `${this.destinationPath}`;
        } else {
            this.destinationDropdownTarget.classList.add('error');
            this.submitBtnTarget.setAttribute("disabled", "");
        }
    }

    submitPickUnit(e) {
      if (e) {
        e.preventDefault();
      }
  
      this.saveSearchDataToCookies();
  
      if (this.adultsCountTarget.value !== "" &&
        this.adultsCountTarget.value !== "0") {
        this.searchParams.set("adults", this.adultsCountTarget.value);
      } else {
          this.searchParams.delete("adults");
      }

      if (this.childrenCountTarget.value !== "" &&
          this.childrenCountTarget.value !== "0") {
          this.searchParams.set("children", this.childrenCountTarget.value);
      } else {
          this.searchParams.delete("children");
      }

      if (this.arriveInputTarget.value !== "") {
        this.searchParams.set("checkin", this.arriveInputTarget.value);
      }

      if (this.departInputTarget.value !== "") {
        this.searchParams.set("checkout", this.departInputTarget.value);
      }
  
      let query = this.searchParams.toString();
      if (query !== "") {
          query = `?${query}`
      }
  
      const newUrl = `${window.location.pathname}${query}`;
      window.history.replaceState(null, "", newUrl);
      window.location.reload();
    }

  async loadSuggestions(evt) {
    const suggestionsContainer = this.element.querySelector('[data-suggestions]');
    const suggestionsButton = this.element.querySelector(".need-more-options-button");
    this.searchParams.set("resort", this.element.dataset.resort)
    this.searchParams.set("except", this.element.dataset.except || "")
    await fetch(`/api/search/recommendations?${this.searchParams.toString()}`)
        .then(response => response.text())
        .then(body => {
          if (body !== "") {
            suggestionsContainer.innerHTML = body;
          } else {
            suggestionsContainer.innerHTML = "<blockquote>No results found.</blockquote>";
            suggestionsButton.disabled = true;
            suggestionsButton.ariaDisabled = true;
          }
        })
        .catch(err => {
          suggestionsContainer.innerHTML = "<blockquote>No results found.</blockquote>";
          suggestionsButton.disabled = true;
          suggestionsButton.ariaDisabled = true;
        });
  }
}
