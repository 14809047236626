import {Controller} from "stimulus";
import getFilters from "util/filter_params";

export default class extends Controller {
    static targets = ['adults', 'children']

    maximum = 1
    currentAdults = 0
    currentChildren = 0
    currentTotal = 0
    preloaded = false

    connect() {
        this.maximum = Number(this.data.get('max'));

        //set initial values
        const filters = getFilters({
            adults: this.adultsTarget.value,
            children: this.childrenTarget.value
        }, true);

        this.adultsTarget.value = filters.get('adults') || 0
        this.childrenTarget.value = filters.get('children') || 0

        if (this.data.has('is-initial-max')) {
            this.update();
        }
    }


    update() {
        this.updateCurrent();
        this.adultsTarget.setAttribute('max', this.maximum - this.currentChildren);
        this.childrenTarget.setAttribute('max', this.maximum - this.currentAdults);
    }

    updateCurrent() {
        const filters = getFilters({
            adults: this.adultsTarget.value,
            children: this.childrenTarget.value
        });
        this.currentAdults = Number(filters.get('adults') || 0);
        this.currentChildren = Number(filters.get('children') || 0)
        this.currentTotal =  this.currentAdults + this.currentChildren;
    }

}
