import "./index.css";

// Account
import "components/account/account";
import "components/account_reservation/account_reservation";

// Contact
import "components/contact_form/contact_form";

// Global
import "components/chat_bubble/chat_bubble";
import "components/checkin_confirmation_form/checkin_confirmation_form";
import "components/footer/footer";
import "components/gdpr_cookie/gdpr_cookie";
import "components/global_banner/global_banner";
import "components/google_tag_manager/google_tag_manager";
import "components/header/header";
import "components/header_new/header_new";
import "components/destinations_list_item/destinations_list_item";
import "components/sub_menu_mobile/sub_menu_mobile";
import "components/sub_header/sub_header";
import "components/sub_menu_mobile_auth/sub_menu_mobile_auth";
import "components/inc_dec_number/inc_dec_number";
import "components/date_range_picker/date_range_picker";
import "components/destinations_list/destinations_list";
import "components/breadcrumbs/breadcrumbs";
import "components/modal_unit_types/modal_unit_types";
// temporary import of the booking sidebar
import "components/pdp_booking_sidebar/pdp_booking_sidebar";

import "components/lazy_image/lazy_image";
import "components/newsletter/newsletter";
import "components/newsletter_form/newsletter_form";
import "components/newsletter_footer/newsletter_footer";
import "components/newsletter_modal/newsletter_modal";
import "components/page/page";
import "components/sr_skip_links/sr_skip_links";
import "components/navigation_searchbar/navigation_searchbar";
import "components/navigation_search_sidebar/navigation_search_sidebar";

// Modal
import "components/account_edit_modal/account_edit_modal";
import "components/add_email_modal/add_email_modal";
import "components/create_account_modal/create_account_modal";
import "components/error_modal/error_modal";
import "components/forgot_password_modal/forgot_password_modal";
import "components/labeled_input/labeled_input";
import "components/modal/modal";
import "components/pending_confirmation_modal/pending_confirmation_modal";
import "components/disabled_user_modal/disabled_user_modal";
import "components/reset_password_modal/reset_password_modal";
import "components/sign_in_modal/sign_in_modal";
import "components/success_modal/success_modal";
import "components/pdp_pick_villa_modal/pdp_pick_villa_modal";

// Video
import "components/video/video";
import "components/video_module/video_module";

// Misc (unaccounted for in the template files)
import "components/animated_squiggle/animated_squiggle";
import "components/colored_text_block/colored_text_block";
import "components/cta_block/cta_block";
import "components/custom_select/custom_select";
import "components/text_block/text_block";
import "components/utility_text/utility_text";
import "components/utility_toggle/utility_toggle";

import { getRequest, postJson } from "./ajax";
import { getBreakpoint } from "./breakpoints";
import { getCookie, setCookie } from "./cookie";
import {
  objectToQuery,
  serializedQueryParams,
  serializeFormData
} from "./forms";

function initiateCheckout() {
  const numGuestsEl = document.getElementById('num_guests');
  if(numGuestsEl && numGuestsEl.value){
    window.location.href = `/checkout?guests=${numGuestsEl.value}`;
  } else{
    window.location.href = "/checkout";
  }
}

function storeQuote(params) {
  // localStorage.setItem('quote', JSON.stringify(params))
  setCookie("quote", JSON.stringify(params), 1);
}

function getQuote() {
  // return JSON.parse(localStorage.getItem('quote'))
  return JSON.parse(getCookie("quote"));
}

function getQuoteParams() {
  // return JSON.parse(localStorage.getItem('quote'))
  return JSON.parse(getCookie("quote_params"));
}

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

window.Site = {
  getBreakpoint,
  postJson,
  getRequest,
  serializeFormData,
  serializedQueryParams,
  objectToQuery,
  initiateCheckout,
  storeQuote,
  getQuote,
  getQuoteParams,
  quoteStored: false
};
