import { Controller } from "stimulus";
import { setCookie } from "init/cookie";
import { useDispatch } from "stimulus-use";


export default class extends Controller {
  static targets = [
    "dropdown", "dropdownBtn", "searchSidebarContainer", "destination", "destinationTitle",
    "adultsCount", "childrenCount", "arriveInput", "departInput", "datesLabel", "submitBtn",
    "searchSidebar", "inputArrowIcon", "destinationDropdownBlock", "dropdownBtnDestination"
  ];

  connect() {
    useDispatch(this);
    this.cityDestinationType = 'city';
    this.resortDestinationType = 'resort';
    this.defaultDestination = '/stay'
    this.resortLevel = this.destinationDropdownBlockTarget.dataset.resort;
    this.resortLink = this.destinationDropdownBlockTarget.dataset.slug;
    this.pageType = this.destinationDropdownBlockTarget.dataset.type;

    this.setInitialDestination();
    this.updateDates();
    this.saveSearchDataToCookies();
  }

  open() {
    this.searchSidebarContainerTarget.classList.add('open');
    document.body.classList.add("overflow-hidden-below-desktop");
    this.handleSidebarOverflow();
  }

  close() {
    if (!this.searchSidebarContainerTarget.classList.contains('open')) return;

    this.searchSidebarContainerTarget.classList.remove('open');
    document.body.classList.remove("overflow-hidden-below-desktop");
  }

  resetDates() {
    this.datesLabelTarget.textContent = `Select Dates`;
  }

  setInitialDestination() {
    this.destinationPath = this.defaultDestination;
    const locationPath = window.location.toString().replace(window.location.search, "");

    const matchedDestinations = this.destinationTargets.filter((destination) => {
      const destinationLinkEl = destination.querySelector('a');
      if (!destinationLinkEl) return false;
      const destinationLink = destinationLinkEl.getAttribute('href');
      return locationPath.includes(destinationLink);
    })

    if (!matchedDestinations.length) {

      if (this.resortLevel) {
        // work just for pdp page bc it is resort level but we can't check it using url
        this.destinationPath = this.resortLink;
        this.destinationType = this.pageType;
        this.destinationTitleTarget.textContent = this.resortLevel;
        if (this.destinationType === this.cityDestinationType) {
          this.dropdownBtnDestinationTarget.classList.add('block-dropdown');
        }
        return
      }
  
      const cityDestination = this.destinationTargets.find(destination => 
        destination.getAttribute("item-type") === this.cityDestinationType
      );
  
      if (cityDestination) {
        const cityLinkEl = cityDestination.querySelector("a");
        if (cityLinkEl) {
          this.destinationPath = cityLinkEl.getAttribute("href");
          this.destinationType = cityDestination.getAttribute("item-type");
          this.destinationTitleTarget.textContent = cityDestination.textContent;
          this.dropdownBtnDestinationTarget.classList.add('block-dropdown');
          return;
        }
      }

      
    } 

    if (matchedDestinations.length) {
      const destination = matchedDestinations.reduce((el1, el2) => {
        const [link1, link2] = [el1, el2].map((el) => el.querySelector('a').getAttribute('href'));
        const activeEl = link1.length > link2.length ? el1 : el2;
        activeEl.classList.add('active')
        return activeEl;
      })
      this.destinationPath = destination.querySelector('a').getAttribute('href');
      this.destinationType = destination.getAttribute('item-type');
      this.destinationTitleTarget.textContent = destination.textContent;
      if (this.destinationType === this.cityDestinationType) {
        this.dropdownBtnDestinationTarget.classList.add('block-dropdown');
      }
    }
  }

  handleMouseDown(e) {
    const isSidebar = this.searchSidebarTarget.contains(e.target);
    const isDropdown = this.dropdownTargets.find((dropdownTarget) => dropdownTarget.contains(e.target));
    const isDropdownBtn = this.dropdownBtnTargets.find((dropdownBtnTarget) => dropdownBtnTarget.contains(e.target));
    if (!isDropdown && !isDropdownBtn) {
      this.closeDropdowns();
    }

    if (!isSidebar) {
      this.close();
    }
  }

  toggleDropdown(e) {
    const dropdownButton = e.currentTarget
    const dropdown = this.dropdown(dropdownButton);
    const icon = dropdownButton.querySelector('.collapse-icon')

    this.dropdownTargets.forEach((dropdownTarget) => {
      if (dropdownTarget === dropdown) return;
      this.closeDropdown(dropdownTarget);
    })

    icon.classList.toggle('active')
    dropdown.classList.toggle('open');
    dropdownButton.classList.toggle('sharp-corners');

    this.handleSidebarOverflowOnDropdownToggle(dropdown);
    
  }

  handleSidebarOverflow() {
    const sidebar = this.searchSidebarTarget;

    // if window height is low make container scrollable
    if (sidebar.offsetHeight >= window.innerHeight * 0.9) {
      sidebar.classList.add('scrollable');
    }
  }

  handleSidebarOverflowOnDropdownToggle(dropdown) {
  const sidebar = this.searchSidebarTarget;

  const sidebarRect = sidebar.getBoundingClientRect();
  const dropdownRect = dropdown.getBoundingClientRect();

  const availableSpace = window.innerHeight - (dropdownRect.top - window.scrollY);

  if (dropdownRect.height > availableSpace) {
    sidebar.classList.add('scrollable-for-dropdown');

    const dropdownBottomRelativeToSidebar = dropdownRect.bottom - sidebarRect.top;
    const overflowAmount = dropdownBottomRelativeToSidebar - sidebarRect.height;

    sidebar.scrollTop += overflowAmount;
  } else {
    sidebar.classList.remove('scrollable-for-dropdown');
  }
}



  closeDropdown(dropdown) {
    const dropdownButton = this.dropdownButton(dropdown);
    const icon = dropdownButton.querySelector('.collapse-icon');
    if (icon.classList.contains('active')) {
      icon.classList.toggle('active');
    }
    dropdown.classList.remove('open');
    dropdownButton.classList.remove('sharp-corners');
  }

  dropdown(sibling) {
    const siblings = [...sibling.parentNode.children].filter(c => c !== sibling);
    const dropdown = this.dropdownTargets.find((dropdownTarget) => siblings.includes(dropdownTarget));
    return dropdown;
  }

  dropdownButton(sibling) {
    const siblings = [...sibling.parentNode.children].filter(c => c !== sibling);
    const dropdownButton = this.dropdownBtnTargets.find((dropdownBtnTarget) => siblings.includes(dropdownBtnTarget));
    return dropdownButton;
  }

  closeDropdowns() {
    this.dropdownTargets.forEach((dropdownTarget) => {
      this.closeDropdown(dropdownTarget);
    })
  }

  selectDestination(e) {
    e.preventDefault();

    const destination = e.currentTarget
    const destinationLink = e.currentTarget.querySelector('a');

    if (!destinationLink) return;

    this.destinationTitleTarget.textContent = destination.textContent;
    this.destinationPath = destinationLink;
    this.destinationType = destination.getAttribute('item-type');
    this.submitBtnTarget.removeAttribute("disabled");
    this.dropdownBtnDestinationTarget.classList.remove("error");
    this.closeDropdowns();

  }

  updateDates() {
    if (!this.arriveInputTarget.value || !this.departInputTarget.value) {
      return;
    }

    // replace '-' with '/' to avoid effecting timezone when creatin date object from a string
    const arriveDate = new Date(this.arriveInputTarget.value.replace(/-/g, "/"));
    const departDate = new Date(this.departInputTarget.value.replace(/-/g, "/"));
    const arriveDateFull = arriveDate.toLocaleDateString(
      'en-US', { year:"numeric", month:"short", day:"numeric"}
    ).replace(",", "").replace(/(\d{1,2}) (\d{4})$/, '$1, $2');
    const departDateFull = departDate.toLocaleDateString(
      'en-US', { year:"numeric", month:"short", day:"numeric"}
    ).replace(",", "").replace(/(\d{1,2}) (\d{4})$/, '$1, $2');

    this.datesLabelTarget.textContent = `${arriveDateFull} - ${departDateFull}`;
  }

  updateSubmitButtonState() {
    if (this.destinationPath && this.destinationPath !== this.defaultDestination) {
      this.submitBtnTarget.removeAttribute("disabled");
    } else {
      this.submitBtnTarget.setAttribute("disabled", '');
    }
  }

  saveDatesToCookies() {
    const arrive = this.arriveInputTarget.value;
    const depart = this.departInputTarget.value;

    if (!arrive || !depart) return;

    const dates = {
      checkin: arrive,
      checkout: depart
    };
    setCookie("filter_by_dates", JSON.stringify(dates), 3);
  }

  saveGuestsToCookies() {
    const adultsCount = this.adultsCountTarget.value;
    const childrenCount = this.childrenCountTarget.value;

    if (!adultsCount && !childrenCount) return

    const guests = {
      adults: adultsCount,
      children: childrenCount
    };
    setCookie("filter_by_guests", JSON.stringify(guests), 3);
  }

  saveSearchDataToCookies() {
    this.saveDatesToCookies();
    this.saveGuestsToCookies();
  }

  submit(e) {
    e.preventDefault();
    if (!this.destinationType && !this.resortLevel) {
      this.submitBtnTarget.setAttribute("disabled", "");
      this.dropdownBtnDestinationTarget.classList.add("error");
      return
    }
    const arrive = this.arriveInputTarget.value;
    const depart = this.departInputTarget.value;
    const adultsCount = this.adultsCountTarget.value;
    const childrenCount = this.childrenCountTarget.value;
    let queryData = {};

    this.saveSearchDataToCookies();

    if(adultsCount !== '0') {
      queryData = Object.assign(queryData, { adults: adultsCount });
    }

    if(childrenCount !== '0') {
      queryData = Object.assign(queryData, { children: childrenCount });
    }

    if (arrive) {
        queryData = Object.assign(queryData, { checkin: arrive });
    }

    if (depart) {
        queryData = Object.assign(queryData, { checkout: depart });
    }

    /* eslint-disable-next-line no-undef */
    const query = Site.objectToQuery(queryData);

    if (this.destinationType === this.resortDestinationType) {
      window.location = query ? `${this.destinationPath}/accommodations?${query}` : `${this.destinationPath}/accommodations`;
    } else {
      window.location = query ? `${this.destinationPath}?${query}` : this.destinationPath;
    }
  }
}
